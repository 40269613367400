import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-15a.png'
import image2 from 'images/project-15b.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Titahi Bay lifesaver"
      meta={[
        {
          name: 'description',
          content: '',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Titahi Bay lifesaver"
      intro="The Trust assisted a family living in Porirua&rsquo;s Titahi Bay to paint their home and carry out some deferred maintenance."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            This provided a valuable work opportunity for some young people to help and gain practical work experience by learning painting skills.
          </Paragraph>
          <Paragraph>
            Despite the bad weather, good progress was made and a big thank you goes to Dulux for the paint and Geeves for the scaffolding around the house.
          </Paragraph>
          <Paragraph>
            Homeowner, Amanda, describes the Ryhaven Trust as a &ldquo;literal lifesaver&rdquo;.
          </Paragraph>
          <Paragraph>
            &ldquo;The staff involved were respectful and did more than expected, thinking of things that would improve my life. The finished house is enjoyable to be in, instead of causing me worry. No more awake nights for me worrying about my house!&rdquo;
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
